<template>
  <section>
    <v-card
      class="section-card mb-4"
      color="white"
      v-for="(section, i) in sections"
      :to="{ name: 'Section', params: { section: section.cat_id } }"
      :key="i"
      height="116"
    >
      <div class="d-flex flex-no-wrap">
        <v-avatar size="116">
          <v-img :src="section.photo_url" />
        </v-avatar>

        <div class="section-name dark--text">{{ section.category_name }}</div>
      </div>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "CatalogSections",

  props: ["parent"],

  computed: {
    sections() {
      if (this.parent) {
        return this.$store.state.catalog.sections.find(
          (s) => s.cat_id == this.parent
        )?.child;
      } else {
        return this.$store.state.catalog.sections;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section-card {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;

  .v-avatar {
    border-radius: $border-radius-root 0 0 $border-radius-root;
  }
}

.section-name {
  display: flex;
  align-items: center;
  padding: 12px 30px 12px 40px;
  word-wrap: break-word;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
}
</style>
