<template>
  <v-container class="py-7">
    <div v-if="!$store.getters['app/isQrMenu']" class="mb-6">
      <OrderTypeButtons />
    </div>
    <catalog-sections />
  </v-container>
</template>

<script>
import CatalogSections from '@/components/CatalogSections'
import OrderTypeButtons from '@/components/OrderTypeButtons'

export default {
  name: 'Catalog',
  components: {
    CatalogSections,
    OrderTypeButtons,
  },
  mounted() {
    document.querySelector('title').innerText = 'Каталог'
  },
}
</script>
